import React from "react";
import logo from "../img/logo/proprt_logo_final.png";
import {
  FaDiscord,
  FaTwitter,
  FaFacebook,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsMedium } from "react-icons/bs";
import { default as ic_etherscan } from "./img/etherscan-logo-light-circle.svg";

export default function Footer() {
  return (
    <section
      id="footer"
      style={{ backgroundColor: "#4AB29D", padding: 0, paddingBottom: "2rem" }}
    >
      <div
        className="container row text-center mx-auto"
        style={{
          padding: 0,
          paddingTop: "2rem",
          justifyContent: "center",
          width: "100%",
          display: "flex",
        }}
      >
        <div className="d-flex justify-content-between">
          <span className="flex-column align-items-start text-start text-white ">
            <div className="d-flex justify-content-between">
              <FaDiscord
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://discord.gg/MgCe6Gzk2W");
                }}
                size={30}
              />
              <FaTwitter
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://twitter.com/proprt_io");
                }}
                size={30}
              />
              <FaFacebook
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/Proprtio-113952484660475"
                  );
                }}
                size={30}
              />
              <BsMedium
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4"
                  );
                }}
                size={30}
              />
            </div>
            <div className="d-flex justify-content-between">
              <AiFillInstagram
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                size={30}
                onClick={() => {
                  window.open("https://www.instagram.com/proprt.io/");
                }}
              />
              <FaGithub
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://github.com/PROPRT-io");
                }}
                size={30}
              />
              <FaLinkedin
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://www.linkedin.com/company/proprt-io/");
                }}
                size={30}
              />
              <img
                src={ic_etherscan}
                alt="etherscan"
                className=" w-8 m-1  h-auto  flex-auto"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://etherscan.io/token/0x832a2f6a1bbd5e8e45039d87f62725e7a9e00041"
                  );
                }}
                width={30}
                color="white"
              />
            </div>
          </span>
          <span className="felx text-start">
            <a
              href="https://proprt.io/marketplace"
              target="_blank"
              rel="noreferrer"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Marketplace</p>
            </a>
            <a href="https://proprt.io/faq" target="_blank" rel="noreferrer">
              <p style={{ fontWeight: "500", fontSize: "15px" }}>
                How it works
              </p>
            </a>
            <a href="https://ico.proprt.io/" target="_blank" rel="noreferrer">
              <p style={{ fontWeight: "500", fontSize: "15px" }}>
                Initial Coin Offering (ICO)
              </p>
            </a>
          </span>
          <span className=" flex-column  text-start">
            {/* <a
              href="https://proprt.io/PROPRT.io-Whitepaperv0.3.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Whitepaper</p>
            </a>
            <a
              href="https://proprt.io/PROPRT.io-Tokenomics-v0.4.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Tokenomics</p>
            </a> */}
            <a
              // href="https://proprt.io/PROPRT.io-Tokenomics-v0.4.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>
                Whitepaper & Tokenomics updates coming soon.
              </p>
            </a>
            {/* <a
              href="/PROPRT-Pitch-Deck-v5.0.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Pitch Deck</p>
            </a> */}
            <a
              // href="https://www.canva.com/design/DAFZyQP9CM0/4FQXvADlqIhPnW5NrkE99A/view#1"
              href="https://doc.storydoc.ai/kACGQ0"
              rel="noreferrer"
              target="_blank"
            >
              <p style={{ fontWeight: "500", fontSize: "15px" }}>Pitch Deck</p>
            </a>
          </span>
        </div>

        {/* 
        
        <--!  -->
        
        
        */}
        <div className="d-sm-flex justify-content-between mt-5">
          <a href="mailto:hello@proprt.io" target="_blank" rel="noreferrer">
            <p>hello@proprt.io</p>
          </a>
          {/* <a href="https://proprt.io/" target="_blank">
            <p style={{ fontWeight: "500", fontSize: "15px" }}>
              © PROPRT io. All rights reserved.
            </p>
          </a> */}
          <a href="https://proprt.io/" target="_blank" rel="noreferrer">
            <p>© PROPRT.io</p>
          </a>
        </div>
      </div>
    </section>
  );
}
